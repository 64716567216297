import { makeAutoObservable, reaction } from "mobx";

import { Store } from "./Store";
import { ApiClient } from "~libs/api";
import { tx } from "~libs/i18n";
import { clearHash } from "~libs/utils";
import { IFormData, formDataToApiData, IFieldSubset, IFieldGroup } from "~models/FormData";
import { ISingleDocumentTaxonomy, ISingleWordpressTermNodeNodeType } from "~models/Taxonomies";

type FormDataKey = "title" | "abstract" | "en_files" | "approvedPolicy" | "consentTerms" | "externalUse" | "documentCountries" | "documentTopics" | "documentKeywords" | "documentAuthors" | "documentContentTypes" | "documentPublishers" | "sof";

interface ISelectedTaxonomy {
  nodeType: ISingleWordpressTermNodeNodeType;
  id: string;
  databaseId: number;
  name: string;
  slug: string;
  count: number;
}

const taxonomyCaseMap = {
  document_topics: "documentTopics",
  document_keywords: "documentKeywords",
  document_countries: "documentCountries",
  document_authors: "documentAuthors",
  document_content_types: "documentContentTypes",
  document_publishers: "documentPublishers"
};

const transformFieldGroupToFormElements = (data: any): IFieldGroup[] => {
  const fieldGroups = data?.map(group => {
    return {
      ...group,
      taxonomy: taxonomyCaseMap[group.taxonomy],
      fields: group?.fields.map(field => ({
        ...field,
        value: field.value ?? field.default_value,
        required: !!field.required || field?.wrapper?.class?.includes("required"),
        choices: !!field?.choices
          ? Object.values(field.choices)?.map((choice: string) => ({
              value: choice,
              selected:
                field?.default_value ===
                  choice
                    ?.replace(/\s+/g, "-")
                    .replace(/[^\w\s\-]/gi, "") // ACF is stupid
                    .toLowerCase() || field?.default_value === choice
            }))
          : []
      }))
    };
  });
  return fieldGroups;
};

export class UploadDocumentsStore {
  private open: boolean = false;

  reCaptchaValid: boolean = false;

  requestStatus: "idle" | "pending" = "idle";

  private getInitialFormState: () => { [key in FormDataKey]: IFormData<any> } = () => {
    return {
      title: {
        value: "",
        method: value => !!value?.length,
        valid: false
      },
      abstract: {
        value: "",
        method: value => !!value?.length,
        valid: false
      },
      en_files: {
        value: [] as File[],
        method: value => !!value?.length,
        valid: false
      },
      approvedPolicy: {
        value: false as boolean,
        method: value => !!value,
        valid: false
      },
      consentTerms: {
        value: false as boolean,
        method: value => !!value,
        valid: false
      },
      externalUse: {
        value: false as boolean,
        method: value => !!value,
        valid: true
      },
      documentTopics: {
        value: [],
        method: _ => true,
        valid: true
      },
      documentPublishers: {
        value: [],
        method: value => !!value.length,
        valid: true
      },
      documentContentTypes: {
        value: [],
        method: value => !!value.length,
        valid: true
      },
      documentKeywords: {
        value: [],
        method: value => !!value.length,
        valid: true
      },
      documentAuthors: {
        value: [],
        method: _ => true,
        valid: true
      },
      documentCountries: {
        value: [],
        method: _ => true,
        valid: true
      },
      sof: {
        value: "",
        method: value => !!value.length,
        valid: true
      }
    };
  };

  formData = this.getInitialFormState();

  pageYOffset: number = 0;

  extraFieldGroups: IFieldGroup[] = [];

  get isOpen() {
    return this.open;
  }

  get isEvaluationsSelected() {
    return this.formData.documentContentTypes.value.some((type: ISelectedTaxonomy) => ["evaluations", "assessments"].includes(type.slug));
  }

  get extraFieldsIsValid() {
    return (
      this.extraFieldGroups
        // Filter out non-active fields
        .filter((group: IFieldGroup) => !!this.formData[group.taxonomy]?.value?.find(term => group?.terms?.includes(term.slug)))
        .every(group => {
          if (group.title === "Ethics") {
            const selectedEthic = group.fields.find(field => field.name === "ethics")?.value;
            const referenceStudy = group.fields.find(field => field.name === "reference_study")?.value;

            switch (selectedEthic) {
              case "No":
                return false;

              case "Yes from SC":
                return !!referenceStudy;

              default:
                return true;
            }
          } else {
            return group.fields.filter(field => !!field.required).every(field => !!field.value);
          }
        })
    );
  }

  get isUploadValid() {
    return !(Object.keys(this.formData || {}) as FormDataKey[]).find(key => !this.formData[key].valid) && this.reCaptchaValid && this.extraFieldsIsValid;
  }

  constructor(private store: Store, private client: ApiClient) {
    makeAutoObservable(this);
    reaction(
      () => this.open,
      open => {
        if (open) {
          this.setPageYOffset();
          this.store.sectionsContainerStore.setScrollPos();
        } else {
          this.store.sectionsContainerStore.resetScrollPos(this.pageYOffset);
        }
      },
      {
        fireImmediately: true
      }
    );
    reaction(
      () => [this.store.authenticationStore.jwtAuthToken, this.store.authenticationStore.authenticationStoreHydrated],
      () => {
        this.handleHashChange();
      }
    );
    /**
     * TODO: Rework this if more cases of selected values changing required arises
     */
    reaction(
      () => [this.isEvaluationsSelected],
      () => {
        if (this.isEvaluationsSelected) {
          this.formData.sof.valid = this.formData.sof.method(this.formData.sof.value);
        } else {
          this.formData.sof.valid = true;
        }
      }
    );
    reaction(
      () => [this.formData.externalUse.value],
      () => {
        if (this.formData.approvedPolicy.value) {
          this.formData.approvedPolicy.value = false;
          this.formData.approvedPolicy.valid = false;
        }
      }
    );
  }

  scrollPos = () => {
    if (typeof window !== undefined && window.pageYOffset === 0) {
      this.store.sectionsContainerStore.resetScrollPos();
    }
  };

  setPageYOffset = () => {
    if (typeof window !== undefined) {
      this.pageYOffset = window.pageYOffset;
    }
  };

  openModal = () => {
    this.resetFormData();
    this.reCaptchaValid = false;
    this.open = true;
  };

  closeModal = () => {
    this.open = false;
    clearHash();
  };

  toggleReCaptcha = () => {
    this.reCaptchaValid = !this.reCaptchaValid;
  };

  resetFormData = () => {
    this.reCaptchaValid = false;
    this.formData = this.getInitialFormState();
  };

  onUpload = async () => {
    if (!this.isUploadValid || this.requestStatus === "pending" || !this.store.userStore.currentUser) {
      return;
    }
    this.requestStatus = "pending";

    if (!this.store.userStore.isStaffUser) {
      this.formData.externalUse.value = true;
    }

    const formattedData = {
      ...this.formData,
      documentCountries: formatTaxonomyString(this.formData.documentCountries),
      documentTopics: formatTaxonomyString(this.formData.documentTopics),
      documentKeywords: formatTaxonomyString(this.formData.documentKeywords),
      documentAuthors: formatTaxonomyString(this.formData.documentAuthors),
      documentPublishers: formatTaxonomyString(this.formData.documentPublishers),
      documentContentTypes: formatTaxonomyString(this.formData.documentContentTypes)
    };

    const data: any = formDataToApiData(formattedData);
    if (!!this.extraFieldGroups) {
      this.extraFieldGroups
        .reduce((acc, group) => acc.concat(group.fields.filter(f => !!f.value)), [])
        .forEach(field => {
          data[field.name] = field.value;
        });
    }
    data.email = this.store.userStore.currentUser.email;

    this.store.uiStore.showGlobalMessage(tx("UploadDocumentsModal.uploadPending"), "neutral", 60000);

    const response = await (this.store.userStore.isStaffUser ? this.client.createInternalDocument(data) : this.client.createDocument(data));
    if (response.ok) {
      this.store.uiStore.showGlobalMessage(tx("UploadDocumentsModal.uploadSuccess"), "positive");
      this.resetFormData();
      this.closeModal();
    } else if (response.problem == "TIMEOUT_ERROR") {
      this.store.uiStore.showGlobalMessage(tx("UploadDocumentsModal.timeout"), "error");
    } else if (response.status === 403) {
      this.store.uiStore.showGlobalMessage(tx("UploadDocumentsModal.errorTitleUsed", { title: data.title }), "error");
    } else {
      this.store.uiStore.showGlobalMessage(tx("UploadDocumentsModal.errorTitleUsed", { title: data.title }), "error");
    }

    this.requestStatus = "idle";
  };

  getExtraFields = async (subset: IFieldSubset) => {
    const response: any = await this.client.getFields(subset);
    if (response.ok) {
      this.extraFieldGroups = transformFieldGroupToFormElements(response.data);
    }
  };

  setAndValidate = (data: IFormData<any>, value: any) => {
    data.value = value;
    this.validate(data);
  };

  validate = (data: IFormData<any>) => {
    data.valid = data.method(data.value);
  };

  handleHashChange = () => {
    if (window.location.hash === "#upload_content") {
      if (!this.store.authenticationStore.authenticationStoreHydrated) return;

      if (!this.store.authenticationStore.jwtAuthToken) {
        !this.store.loginStore.loginModalOpen && this.store.loginStore.openLoginModal();
        history.pushState("", document.title, window.location.pathname + window.location.search); // ?
      } else {
        !this.open && this.openModal();
      }
    }
  };

  onMount = () => {
    this.handleHashChange();
    window.addEventListener("hashchange", this.handleHashChange, false);
  };

  onUnmount = () => {
    window.removeEventListener("hashchange", this.handleHashChange, false);
  };
}

function formatTaxonomyString(taxonomy: IFormData<ISingleDocumentTaxonomy[]>): IFormData<string> {
  return {
    ...taxonomy,
    value: taxonomy.value.map(term => term.databaseId || term.name).join(",")
  };
}
